import * as React from "react";
import Layout from "../components/Layout";

import kidsParty from "../images/kids-party.jpg";
import adultParty from "../images/adult-party.jpg";
import corporateParty from "../images/corporate-event.jpg";

export default function collaborationAndCelebrationPage() {
  return (
    <div>
      <Layout>
        <div className="w-full py-24 px-4">
          <div className="flex justify-center">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-10 font-font2">
              COLLABORATIONS & CELEBRATIONS
            </h1>
          </div>
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
            <div className="flex flex-col justify-center sm:px-4 md:px-4">
              <h1 className="md:text-3xl sm:text-2xl text-xl font-bold py-4 font-font3">
                Groups & Parties
              </h1>
              <p className="md:text-2xl sm:text-xl text-l font-font1">
                Jazz up your party with a private cooking class that’s just for
                you. Enjoy the company of friends and family while baking or
                cooking delicious food - filling tummies and building memories.
              </p>
            </div>
            <img className="w-[500px] mx-auto my-10" src={adultParty} alt="" />
          </div>
          <div className="">
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 ">
              <div className="flex flex-col justify-center sm:px-4 md:px-4">
                <h1 className="md:text-3xl sm:text-2xl text-xl font-bold py-4 font-font3">
                  Children’s Parties
                </h1>
                <p className="md:text-2xl sm:text-xl text-l font-font1">
                  Celebrate your child’s special day with a meaningful activity,
                  or simply bring their friends together to learn some hands-on
                  life skills. They will be cooking/baking up a storm in our
                  kitchens. The best part for parents, our clean-up crew will
                  handle all the mess/aftermath :)
                </p>
              </div>
              <img className="w-[500px] mx-auto my-10" src={kidsParty} alt="" />
            </div>
          </div>
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
            <div className="flex flex-col justify-center sm:px-4 md:px-4">
              <h1 className="md:text-3xl sm:text-2xl text-xl font-bold py-4 font-font3">
                Corporate Events & Team Building
              </h1>
              <p className="md:text-2xl sm:text-xl text-l font-font1">
                An open day, a family day or simply a day for colleagues to get
                to know each other better, we’ll help you plan the perfect
                event. Get your teams out for a food battle or bring them
                together to prepare a delicious feast. Whatever the theme, there
                will be plenty of fun and laughter.
              </p>
            </div>
            <img
              className="w-[500px] mx-auto my-10"
              src={corporateParty}
              alt=""
            />
          </div>
        </div>
      </Layout>
    </div>
  );
}
